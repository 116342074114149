import React from "react";
import { graphql } from "gatsby";

import FixtureComponent from "../../../components/Fixture";
import { SeoComponent } from "../../../components/SeoComponent";
import { useAllMatches } from "../../../hooks/useAllMatches";

function TeamFixture(props) {
  const team = props.data.teamsJson;
  const { areAllMatchesPlayed, allMatches } = useAllMatches();
  const matches = allMatches.filter(
    (m) => m.home.slug === team.slug || m.away.slug === team.slug
  );
  return (
    <FixtureComponent
      matches={matches}
      team={team.fullName}
      areAllMatchesPlayed={areAllMatchesPlayed}
      title={`${team.fullName} Süper Lig Fikstürü`}
    />
  );
}

export default TeamFixture;

export const query = graphql`
  query ($slug: String) {
    teamsJson(slug: { eq: $slug }) {
      id
      fullName
      shortName
      slug
    }
  }
`;

export const Head = ({ data }) => {
  const { fullName } = data.teamsJson;
  return (
    <SeoComponent
      title={`${fullName} Süper Lig Fikstürü`}
      description={`Türkiye Futbol Süper Ligi 2024-25 Sezonu ${fullName} fikstürü ve maç sonuçları`}
      keywords={`${fullName}, maç sonuçları, mac sonuclari, fikstür, fikstur, Süperlig, Superlig, ensuperlig, ensüperlig`}
    />
  );
};
